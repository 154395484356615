<template>
  <div id="app">
    <router-view />
    <debug-menu v-if="nodeEnv == 'development'" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import DebugMenu from '@/components/DebugMenu.vue';

@Component({
  components: {
    DebugMenu,
  },
})
export default class App extends Vue {
  // is set to the right value on 'mounted' event.
  private root: HTMLHtmlElement | null = null;

  get nodeEnv() {
    return process.env.NODE_ENV;
  }

  public mounted() {
    this.root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)

    this.detectBrowsers();
    this.lazyLoadBackgrounds();
  }

  private detectBrowsers(): void {
    const ua = window.navigator.userAgent;
    const iOS: boolean = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const webkit: boolean = /WebKit/i.test(ua);
    const iosChrome = /CriOS/i.test(ua);

    const iOSSafari = iOS && webkit && !iosChrome;

    // add classes for css workarounds.

    if (this.root && iOSSafari) {
      this.root.classList.add('safari-ios');
    }
  }

  private lazyLoadBackgrounds(): void {
    const imagesNames = ['ej-beach', 'bg-pier'];
    for (const imageName of imagesNames) {
      const img = new Image();
      img.onload = () => {
        if (!this.root) {
          return;
        }
        this.root.classList.add(`${imageName}-loaded`);
      };
      img.src = require(`@/assets/images/${imageName}.jpg`);
    }
  }
}
</script>

<style lang="scss">
.debug {
  @if $node-env != 'development' {
    display: none;
  }

  background-color: rgba(255, 255, 0, 0.397);
  border: 2px dotted red;
}
* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'villa_didotregular';
  src: url('~@/assets/fonts/villa-didot/villa_didot-webfont.woff2') format('woff2'),
       url('~@/assets/fonts/villa-didot/villa_didot-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

a {
  text-decoration: none;
}

.seagull {
  background: transparent url('~@/assets/images/seagull-1.png') no-repeat center;
  background-size: contain;
  height: 150px;
  width: 150px;
  z-index: 50;
  position: absolute;
  top: 36%;
  right: 2%;
  filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.15));
  /*  animation: float;
  animation-duration: .85s;
  animation-iteration-count: infinite; */
}

.seagull-2 {
  background-image: url('~@/assets/images/seagull-2.png');
  top: 55%;
  right: 85%;
  height: 200px;
  width: 200px;
}

@media only screen and (max-width: 480px) {
  .seagull {
    height: 120px;
    width: 120px;
    right: 0;
    top: 22%;
  }
  .seagull-2 {
    right: 71%;
    top: 58%;
  }
}

html,
body {
  background-color: #e2ede9;
  min-height: 100%;
  height: auto;
  margin: 0;
}

body {
  text-align: center;
  text-transform: uppercase;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 30vh;
  padding-bottom: 60px;
  padding-bottom: 27vw;
  position: relative;
}

body::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 60vh;
  width: 100%;
  z-index: 0;
  background-color: #e2ede9;
  background-image: url('~@/assets/images/ej-beach-nano.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  background-attachment: fixed;
  clip-path: polygon(0 20%, 100% 0%, 100% 100%, 0 100%);
  z-index: 1;
}

body::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 60vh;
  width: 100%;
  z-index: 0;
  background: rgba(255, 255, 255, 0.9) url('~@/assets/images/bg-pier-nano.svg')
    no-repeat center;
  background-attachment: fixed;
  background-size: cover;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%);
}

.safari-ios body::before {
  background-attachment: scroll;
}

// lazy loaded bg images:
.ej-beach-loaded body::after {
  background-image: url('~@/assets/images/ej-beach.jpg');
}
.bg-pier-loaded body::before {
  background-image: url('~@/assets/images/bg-pier.jpg');
}

#app {
  position: relative;
}

body,
#app,
.font-josefin-sans,
.card {
  font-family: 'Josefin Sans', sans-serif !important;
}

.font-bentham {
  font-family: 'Bentham', serif;
}

.font-villa-didot {
  font-family: 'villa_didotregular', 'Bentham33', serif;
  font-weight: normal;
  text-transform: uppercase;
}

.text-color-1 {
  color: #626966;
}

.text-color-2 {
  color: #d0a346;
}

.text-color-3 {
  color: #96b3a3;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #004085;
  border-radius: 0.25rem;
  min-width: 50%;
  margin: 0 20px;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert p:first-child {
  margin-top: 0;
}

.alert p:last-child {
  margin-bottom: 0;
}

.button-primary,
.button-secondary {
  display: block;
  margin: 30px auto;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  outline: none;
}

.button-primary {
  border: 1px solid #d0a346;
  letter-spacing: 0.15em;
  padding: 10px 40px;
  font-size: 15px;
  background-color: #d0a346;
  color: #f7f8f4;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
}

.button-primary:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.button-secondary {
  color: #a0a3a2;
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

.card-container {
  padding: 8px;
  background-image: repeating-linear-gradient(
    -45deg,
    #96b3a3 4px,
    #96b3a3 6px,
    transparent 6px,
    transparent 9px
  );
  margin: -30px auto 0 auto;
}

.card {
  background-color: #f7f8f4;
  padding: 100px 60px 30px 60px;
}
</style>
