<template>
  <div class="page-content page-content-localizacoes wow fadeIn">
    <div class="card-container">
      <div class="card">
        <div class="localizacoes">
          <div class="item">
            <h2 class="font-bentham">Igreja de Recarei</h2>
            <a
              href="https://www.google.com/maps/place/Igreja+de+Recarei/@41.1544725,-8.4137303,17z/data=!3m1!4b1!4m5!3m4!1s0xd2488c767826d01:0xbe7af33f67580b0c!8m2!3d41.1544725!4d-8.4115416?shorturl=1"
              target="_blank"
              rel="noreferrer noopener nofollow"
              class="button-primary font-bentham text-color-2"
              >Direções</a
            >
          </div>
          <div class="item">
            <h2 class="font-bentham">Quinta O Campo</h2>
            <a
              href="https://www.google.com/maps/place/O+Campo,+R.+do+Viso+273,+Freamunde/@41.2930496,-8.3478119,16z/data=!4m6!3m5!1s0xd248d2950bba6bd:0x4906491495293a59!8m2!3d41.2930496!4d-8.3478119!16s%2Fg%2F11b8b403yt"
              target="_blank"
              rel="noreferrer noopener nofollow"
              class="button-primary font-bentham text-color-2"
              >Direções</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Directions extends Vue {}
</script>

<style scoped lang="scss">
.page-content-localizacoes {
  display: block;
  max-width: 800px;
  width: 100%;
  margin: 100px auto 0;
  z-index: 5;
  position: relative;
}
.page-content-localizacoes .card-container {
  display: block;
  margin: -50px auto 0 auto;
}

.page-content-localizacoes .card {
  padding: 40px;
  width: 100%;
  max-width: 800px;
}

.localizacoes {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.localizacoes .item {
  width: 45%;
}

.localizacoes a {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .localizacoes .item {
    width: 100%;
  }
}
</style>
