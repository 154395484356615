<template>
  <div class="page-content">
    <div class="alert alert-danger">
      URL inválido. Por favor confirme o URL.
    </div>
    <Invite :show-guest="false" />
    <!-- :error-message="'URL invalido.'" -->
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Invite from '@/views/Invite.vue';

@Component({
  components: {
    Invite,
  },
})
export default class PageNotFound extends Vue {}
</script>
