<template>
  <Invite :show-guest="false" />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Invite from '@/views/Invite.vue';

@Component({
  components: {
    Invite,
  },
})
export default class Home extends Vue {}
</script>
