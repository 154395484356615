<template>
  <div id="debug-root" class="debug">
    <div id="nav">
      <p>Debug menu</p>
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/invite/x123">Test Invite 1</router-link></li>
        <li><router-link to="/invite/x456">Test Invite 2</router-link></li>
        <li><router-link to="/invite/x789">Test Invite 3</router-link></li>
        <li><router-link to="/invite/x666">Test Invite 4</router-link></li>
        <li><router-link to="/invite/x1337">Test Invite 5</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DebugMenu extends Vue {}
</script>

<style scoped lang="scss">
#debug-root {
  font: small 'Gill Sans', sans-serif;
  margin: 20px;
  position: absolute;
  top: 0;
}

#nav {
  display: inline-block;
  padding: 0px;
  ul {
    padding: 0;
    text-align: left;
    li {
      list-style: none;
      display: inline;
      padding: 5px;
    }
  }
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
