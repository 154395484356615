<template>
  <img :src="imgSrc" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ImgLazy extends Vue {
  @Prop({ default: '' })
  public src!: string;
  @Prop({ default: '' })
  public srcPlaceholder!: string;

  public loaded: boolean = false;

  public get imgSrc(): string {
    return this.loaded ? this.src : this.srcPlaceholder;
  }

  public mounted() {
    const img = new Image();
    img.onload = this.imageLoaded;
    img.src = this.src;
  }

  private imageLoaded(ev: Event) {
    this.loaded = true;
  }
}
</script>

<style scoped lang="scss"></style>
