<template>
  <div>
    <transition name="modal">
      <div v-if="guest && showModalEmbarcar" id="modal-embarcar" class="modal">
        <transition appear name="modal-dialog">
          <div class="modal-dialog">
            <div class="modal-head">
              <h3 class="modal-head-title">Embarcar</h3>
            </div>
            <div class="modal-body">
              <form
                id="form_embarcar_v2"
                action="https://api.web3forms.com/submit"
                name="form_embarcar_v2"
                method="post"
              >
                <input
                  type="hidden"
                  name="access_key"
                  value="325f4e7a-bf0c-4f29-8bc3-f8e6b4afd2d7"
                />
                <!-- Honeypot Spam Protection -->
                <input
                  type="checkbox"
                  name="botcheck"
                  class="hidden"
                  style="display: none"
                />
                <!-- Custom Confirmation / Success Page -->
                <input
                  v-model="formRedirectPage"
                  type="hidden"
                  name="redirect"
                />
                <!-- From Name you want to see in the email
                     Default is "Notifications". you can overwrite here -->
                <input type="hidden" name="from_name" v-model="formFromName">
                <input
                  id="form_name"
                  name="form_name"
                  type="hidden"
                  value="embarcar"
                />
                <input
                  v-model="guestConfirmedEmailSubject"
                  type="hidden"
                  name="subject"
                />
                <input
                  id="guest_id"
                  v-model="guest.id"
                  name="guest_id"
                  type="hidden"
                />
                <div class="form-group">
                  <label for="numero_de_adultos">Adultos</label>
                  <input
                    id="numero_de_adultos"
                    type="number"
                    name="numero_de_adultos"
                  />
                </div>
                <div class="form-group">
                  <label for="criancas_ate_3_anos">Crianças até 3 anos</label>
                  <input
                    id="criancas_0_a_3_anos"
                    type="number"
                    name="criancas_0_a_3_anos"
                  />
                </div>
                <div class="form-group">
                  <label for="criancas_ate_10_anos"
                    >Crianças dos 4 aos 10 anos</label
                  >
                  <input
                    id="criancas_4_a_10_anos"
                    type="number"
                    name="criancas_4_a_10_anos"
                  />
                </div>
                <div class="form-group">
                  <label for="restricoes_alimentares"
                    >Restrições alimentares</label
                  >
                  <textarea
                    id="restricoes_alimentares"
                    name="restricoes_alimentares"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label for="notas">Mensagem (opcional)</label>
                  <textarea id="notas" name="notas"></textarea>
                </div>
                <input
                  id="submit_embarcar"
                  data-target="modal-embarcar"
                  type="submit"
                  value="Confirmar Embarcar"
                  class="button-primary font-bentham text-color-2"
                />
              </form>
              <!-- Close button must be outside the form or prevent the default event (did both).
                -- Having the form inside a transition makes this button submit the form. 🤷‍♂️ -->
              <button
                id="close_embarcar"
                data-target="modal-embarcar"
                class="button-secondary font-bentham"
                @click="closeModalEmbarcar"
              >
                Fechar
              </button>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <transition name="modal">
      <div v-if="guest && showModalEmTerra" id="modal-terra" class="modal">
        <transition appear name="modal-dialog">
          <div class="modal-dialog">
            <div class="modal-head">
              <h3 class="modal-head-title">Ficar em Terra</h3>
            </div>
            <div class="modal-body">
              <form
                id="form_em_terra_v2"
                action="https://api.web3forms.com/submit"
                name="form_em_terra_v2"
                method="post"
              >
                <input
                  type="hidden"
                  name="access_key"
                  value="325f4e7a-bf0c-4f29-8bc3-f8e6b4afd2d7"
                />
                <!-- Honeypot Spam Protection -->
                <input
                  type="checkbox"
                  name="botcheck"
                  class="hidden"
                  style="display: none"
                />
                <!-- Custom Confirmation / Success Page -->
                <input
                  v-model="formRedirectPage"
                  type="hidden"
                  name="redirect"
                />
                <input
                  v-model="guestCantMakeItEmailSubject"
                  type="hidden"
                  name="subject"
                />
                <!-- From Name you want to see in the email
                     Default is "Notifications". you can overwrite here -->
                <input type="hidden" name="from_name" v-model="formFromName">
                <input
                  id="form_name"
                  name="form_name"
                  type="hidden"
                  value="em_terra"
                />
                <input
                  id="guest_id"
                  v-model="guest.id"
                  name="guest_id"
                  type="hidden"
                />
                <div class="form-group">
                  <label for="mensagem">Deixar mensagem</label>
                  <textarea id="mensagem" name="mensagem"></textarea>
                </div>
                <input
                  id="submit_em_terra"
                  data-target="modal-terra"
                  type="submit"
                  value="Confirmar Ficar em Terra"
                  class="button-primary font-bentham text-color-2"
                />
              </form>
              <!-- Close button must be outside the form or prevent the default event (did both).
              -- Having the form inside a transition makes this button submit the form. 🤷‍♂️ -->
              <button
                id="close_em_terra"
                data-target="modal-terra"
                class="button-secondary font-bentham"
                @click.prevent="closeModalEmTerra"
              >
                Fechar
              </button>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component
export default class InviteModals extends Vue {
  @Prop({ default: false })
  public showModalEmbarcar!: boolean;
  @Prop({ default: false })
  public showModalEmTerra!: boolean;
  @Prop({ default: null })
  public guest!: GuestData | null;

  @Emit()
  public closeModalEmbarcar() {
    /* no value to emit */
  }
  @Emit()
  public closeModalEmTerra() {
    /* no value to emit */
  }

  get formRedirectPage() {
    return window.location.origin + this.$route.path + '?form-result=success';
  }

  get guestConfirmedEmailSubject() {
    return '[CASAMENTO] [' + this.guest?.id + '] Confirmado';
  }

  get guestCantMakeItEmailSubject() {
    return '[CASAMENTO] [' + this.guest?.id + '] Não vai';
  }

  get formFromName() {
    return this.guest?.name;
  }

  public mounted() {
    this.$nextTick(function () {
      // preload background images one second after component is mounted
      setTimeout(() => this.preloadBackgroundImages(), 1000);
    });
  }

  public preloadBackgroundImages() {
    const imagePaths = [
      require('@/assets/images/shortlist/IMG_20180918_161003.jpg'),
      require('@/assets/images/shortlist/PhotoGrid_a1_1576962591443.jpg'),
    ];
    for (const imagePath of imagePaths) {
      const img = new Image();
      img.src = imagePath;
    }
  }
}
</script>

<style scoped lang="scss">
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}

.modal-dialog-enter-active,
.modal-dialog-leave-active {
  transition: all 0.3s;
  transition-delay: 1s;
}
.modal-dialog-enter,
.modal-dialog-leave-to {
  opacity: 0;
  transform: rotateY(90deg);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  overflow: auto;
  background-size: cover;
}

/* body::before, */
#modal-embarcar {
  background: rgba(255, 255, 255, 0.9)
    url('~@/assets/images/shortlist/IMG_20180918_161003.jpg') no-repeat center;
  background-size: cover;
}

#modal-terra {
  background: rgba(255, 255, 255, 0.9)
    url('~@/assets/images/shortlist/PhotoGrid_a1_1576962591443.jpg') no-repeat
    center;
  background-size: cover;
}

.modal.show {
  display: block;
}

.modal-dialog {
  background-color: #f7f8f4;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.94);
  margin: 32px auto;
  max-width: 340px;
  width: 100%;
  padding: 32px 16px;
  /*  box-shadow: 0 2px 4px rgba(0,0,0,.65),
        0 18px 36px rgba(0,0,0,.65); */
}

.modal-head {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ddd;
}

.modal-head-title {
  margin: 0;
}

.form-group {
  margin-bottom: 26px;
}

.form-group label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  display: block;
  padding: 16px;
  max-width: 100%;
  width: 100%;
  background-color: #fbfbfb;
  border: 1px solid #ddd;
}

.form-group textarea {
  min-height: 100px;
  min-width: 100%;
}

.form-group input[type='number'] {
  max-width: 100px;
}

.modal .button-primary,
.modal button {
  margin-left: 0;
  padding-left: 12px;
  padding-right: 12px;
}
</style>
