<template>
  <div class="item">
    <a
      href="/upload"
      target="_blank"
      rel="noreferrer noopener nofollow"
      class="button-primary font-bentham text-color-2"
      >Upload Fotos 📷</a
    >
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PhotosUpload extends Vue {}
</script>

<style scoped lang="scss">
.page-content-localizacoes {
  display: block;
  max-width: 800px;
  width: 100%;
  margin: 100px auto 0;
  z-index: 5;
  position: relative;
}
.page-content-localizacoes .card-container {
  display: block;
  margin: -50px auto 0 auto;
}

.page-content-localizacoes .card {
  padding: 40px;
  width: 100%;
  max-width: 800px;
}

.localizacoes {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.localizacoes .item {
  width: 45%;
}

.localizacoes a {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .localizacoes .item {
    width: 100%;
  }
}
</style>
