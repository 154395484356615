import { render, staticRenderFns } from "./DebugMenu.vue?vue&type=template&id=81271a1a&scoped=true"
import script from "./DebugMenu.vue?vue&type=script&lang=ts"
export * from "./DebugMenu.vue?vue&type=script&lang=ts"
import style0 from "./DebugMenu.vue?vue&type=style&index=0&id=81271a1a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81271a1a",
  null
  
)

export default component.exports