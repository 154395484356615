<template>
  <div class="gallery">
    <img-lazy
      class="wow rotateInUpLeft hover-shake"
      :src="img('031.jpg')"
      :src-placeholder="img('031-nano.svg')"
      alt=""
    />
    <div class="grid">
      <img-lazy
        class="wow rotateInUpLeft"
        :src="img('PhotoGrid_b1_1576962874039.jpg')"
        :src-placeholder="img('PhotoGrid_b1_1576962874039-nano.svg')"
        alt=""
      />
      <img-lazy
        class="wow slideInUp"
        :src="img('IMG_20180522_150621.jpg')"
        :src-placeholder="img('IMG_20180522_150621-nano.svg')"
        alt=""
      />
      <img-lazy
        class="wow rotateInUpRight"
        :src="img('IMG_20180901_185908-1.jpg')"
        :src-placeholder="img('IMG_20180901_185908-1-nano.svg')"
        alt=""
      />
    </div>
    <div class="frase-romantica wow fadeInUp">
      <h3>
        A verdadeira <span class="text-color-2">alegria</span> é sorrirmos
        juntos!
      </h3>
    </div>
    <div class="grid">
      <img-lazy
        class="wow rotateInUpLeft"
        :src="img('IMG_20190721_193632.jpg')"
        :src-placeholder="img('IMG_20190721_193632-nano.svg')"
        alt=""
      />
      <img-lazy
        class="wow slideInUp"
        :src="img('IMG_20180825_135851.jpg')"
        :src-placeholder="img('IMG_20180825_135851-nano.svg')"
        alt=""
      />
      <img-lazy
        class="wow rotateInUpRight"
        :src="img('IMG_20191124_102602-1.jpg')"
        :src-placeholder="img('IMG_20191124_102602-1-nano.svg')"
        alt=""
      />
    </div>
    <img-lazy
      class="wow slideInUp"
      :src="img('IMG_20190831_171801.jpg')"
      :src-placeholder="img('IMG_20190831_171801-nano.svg')"
      alt=""
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ImgLazy from '@/components/ImgLazy.vue';

@Component({
  components: {
    ImgLazy,
  },
})
export default class Gallery extends Vue {
  private img(partialSrc: string): string {
    return require('@/assets/images/shortlist/' + partialSrc);
  }
}
</script>

<style scoped lang="scss">
.gallery {
  max-width: 960px;
  margin: 0 auto;
  width: 90%;
  z-index: 5;
  position: relative;
}

.gallery img {
  display: block;
  border: 8px solid #fff;
  // box-shadow: 0 2px 4px #ddd;
  box-shadow: 0 2px 4px rgba(70, 90, 85, 0.3);
  margin: 20px auto;
  height: auto;
  width: 100%;
  transition: all 0.25s ease-in;
}

.gallery img:first-child {
  width: 90%;
  transform: rotate(-2deg);
}

.gallery img:nth-child(2) {
  transform: rotate(2deg);
  width: 80%;
  margin-top: -80px;
  box-shadow: 0 2px 4px #ddd, 0 20px 50px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.gallery img:nth-child(3) {
  margin-top: -50px;
  z-index: 1;
  transform: rotate(1deg);
}

.gallery .grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 120%;
  margin-left: -10%;
}

@media screen and (max-width: 1152px) {
  .gallery .grid {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.gallery .grid img {
  margin: auto;
  width: 30%;
}

.gallery .grid img:nth-child(2) {
  width: 40%;
}

@media screen and (max-width: 680px) {
  .gallery .grid {
    display: block;
  }

  .gallery img {
    border: 4px solid #fff;
    display: inline-block;
  }
  .gallery .grid img:nth-child(1),
  .gallery .grid img:nth-child(2) {
    margin: auto 4%;
    width: 38%;
  }
  .gallery .grid img:nth-child(3) {
    width: 60%;
  }
}

.frase-romantica {
  font-size: 28px;
  margin: 7em auto;
}

@media screen and (max-width: 768px) {
  .frase-romantica {
    font-size: 18px;
    margin: 3em auto;
  }
}

.frase-romantica h3 {
  padding: 1em 0;
}

.hover-shake:hover {
  animation: shakeshake 0.3s ease-in-out infinite !important;
}

@keyframes shakeshake {
  0% {
    transform: scale(1.003);
  }
  50% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}
</style>
